.spin {
  animation: spin 10s infinite ease-in-out;
}

// spin animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
