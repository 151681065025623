$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #adafae !default;
$gray-500: #888 !default;
$gray-600: #555 !default;
$gray-700: #282828 !default;
$gray-800: #222 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #6bbedc !default; // cw
$pink: #e0315a !default; // cw
$yellow: #dda72d !default; // cw
$green: #2bb57a !default; // cw
$cyan: #5d0f3a !default; // cw

$primary: $cyan !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $pink !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: (
  "primary": $success,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);
