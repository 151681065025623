.top-handle {
  position: fixed;
  top: 0;
  right: calc(50% - 25px);
  width: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  z-index: 1001;
  // animate top handle height change
  transition: height 0.1s ease-in-out;
}

// this will continuously spin the element with the spinLeft classname to the left
.spinLeft {
  animation: spinLeft 35s infinite linear;
}

// this will continuously spin the element with the spinRight classname to the right
.spinRight {
  animation: spinRight 35s infinite linear;
}

// this will continuously spin the element with the spinLeft classname to the left
@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

// this will continuously spin the element with the spinRight classname to the right
@keyframes spinRight {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
