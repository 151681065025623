// import the colours from the variables.scss file
@import "../../scss/variables.scss";

.background-yellow {
  background-color: $yellow;
}

@keyframes scrollLogos {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 2)); // Since we duplicated the logos
  }
}

.scrollingLogos {
  display: flex;
  animation: scrollLogos 30s linear infinite;
  white-space: nowrap;
  overflow: hidden;

  > div {
    flex: none;
    padding: 0 5%; // Adjust spacing between logos as needed
  }
}

