// import the colours from the variables.scss file
@import "../../scss/variables.scss";

.spin {
  animation: 1.5s linear 0s infinite heartbeat;
}

@keyframes heartbeat {
  0% { transform:  scale(1); }
  25% { transform: scale(1); }
  30% { transform: scale(1.2); }
  50% { transform: scale(1.1); }
  70% { transform: scale(1.2); }
  100% { transform: scale(1); }
}