/* SCSS */
// https://startbootstrap.com/previews/stylish-portfolio/
// https://stackblitz.com/
// Start inspired by https://httpie.io/

// body {
//   background-color: rgb(30, 25, 25);
//   color: rgb(245, 245, 240);
// }

// .navbar-dark {
//   background-color: rgb(30, 25, 25) !important;
// }

// .bg-dark {
//   background-color: rgb(30, 25, 25) !important;
// }

// ENDS inspired by https://httpie.io/

// html,
// body {
//   height: 100%;
// }
// disabled height 100% because if collab is embedded in an iframe, and we show/hide elements the
// height will be set to 100% of the highest element even if elements are hidden and the height
// shrinks

.gradient {
  background: linear-gradient(
    180deg,
    rgba(94, 189, 62, 1) 34%,
    rgba(255, 185, 0, 1),
    rgba(247, 130, 0, 1),
    rgba(226, 56, 56, 1),
    rgba(151, 57, 153, 1),
    rgba(0, 156, 223, 1) 91%
  );
  -webkit-background-clip: text;
  background-clip: text;
  // color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
}

.rainbow {
  background-image: linear-gradient(to right, $blue, $green, $orange, $red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.rainbowTextAnimatedActive {
  background: linear-gradient(
    to right,
    #6666ff,
    #0099ff,
    #00ff00,
    #ff3399,
    #6666ff
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

.rainbowTextAnimated:hover {
  background: linear-gradient(
    to right,
    #6666ff,
    #0099ff,
    #00ff00,
    #ff3399,
    #6666ff
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

/* Animated rainbow shadow */

.rainbowShadow {
  animation: animateRainbowShadow 20s linear infinite;
}

$rainbowTransparency: 0.3;

@keyframes animateRainbowShadow {
  from,
  to {
    box-shadow: 0 0.5rem 1rem rgba(255, 0, 0, $rainbowTransparency);
  }

  16% {
    box-shadow: 0 0.5rem 1rem rgba(255, 165, 0, $rainbowTransparency);
  }

  32% {
    box-shadow: 0 0.5rem 1rem rgba(255, 255, 0, $rainbowTransparency);
  }

  48% {
    box-shadow: 0 0.5rem 1rem rgba(0, 255, 0, $rainbowTransparency);
  }

  64% {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 255, $rainbowTransparency);
  }

  80% {
    box-shadow: 0 0.5rem 1rem rgba(75, 0, 130, $rainbowTransparency);
  }
}
