/* KEYFRAMES */

@-webkit-keyframes animateCloud {
  0% {
    margin-left: -50%;
  }
  100% {
    margin-left: 100%;
  }
}

@-moz-keyframes animateCloud {
  0% {
    margin-left: -50%;
  }
  100% {
    margin-left: 100%;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: -50%;
  }
  100% {
    margin-left: 100%;
  }
}

.cloud {
  -webkit-animation: animateCloud 35s linear infinite;
  -moz-animation: animateCloud 35s linear infinite;
  animation: animateCloud 35s linear infinite;
}

// /* ANIMATIONS */

// .x1 {
// -webkit-animation: animateCloud 35s linear infinite;
// -moz-animation: animateCloud 35s linear infinite;
// animation: animateCloud 35s linear infinite;

// -webkit-transform: scale(0.65);
// -moz-transform: scale(0.65);
// transform: scale(0.65);
// }

// .x2 {
// -webkit-animation: animateCloud 20s linear infinite;
// -moz-animation: animateCloud 20s linear infinite;
// animation: animateCloud 20s linear infinite;

// -webkit-transform: scale(0.3);
// -moz-transform: scale(0.3);
// transform: scale(0.3);
// }

// .x3 {
// -webkit-animation: animateCloud 30s linear infinite;
// -moz-animation: animateCloud 30s linear infinite;
// animation: animateCloud 30s linear infinite;

// -webkit-transform: scale(0.5);
// -moz-transform: scale(0.5);
// transform: scale(0.5);
// }

// .x4 {
// -webkit-animation: animateCloud 18s linear infinite;
// -moz-animation: animateCloud 18s linear infinite;
// animation: animateCloud 18s linear infinite;

// -webkit-transform: scale(0.4);
// -moz-transform: scale(0.4);
// transform: scale(0.4);
// }

// .x5 {
// -webkit-animation: animateCloud 25s linear infinite;
// -moz-animation: animateCloud 25s linear infinite;
// animation: animateCloud 25s linear infinite;

// -webkit-transform: scale(0.55);
// -moz-transform: scale(0.55);
// transform: scale(0.55);
// }
